<!-- 教学素材 -- 学科知识点 -->
<template>
	<div class="teacher">
		<list-template
			:current-page="page"
			:loading="loading"
			:search-config="searchConfig"
			:table-config="tableConfig"
			:table-data="tableData"
			:total="total"
			@onAdd="$router.push('./add')"
			@onChangePage="handleCurrentChange"
			@onReset="onReset"
			@onSearch="onSearch"
			@onHandle="onHandel"
		></list-template>
		<h-dialog :historyDialogShow="historyDialogShow" :historyList="historyList" @close="historyDialogShow = false"></h-dialog>
	</div>
</template>

<script>
import { tableListMixin } from '@/utils/mixins'
import { zh_opt_history, zhGet, changeObjToArr } from '@/api'
import { setConfigOption } from '@/utils'

export default {
	components: {History},
	_config: {"route": {"path": "list", "meta": {"title": "列表", "keepAlive": true}}},
  mixins: [tableListMixin],
	data() {
		return {
			searchConfig: [
				{
					prop: "subject_id",
					tag: "select",
					placeholder: "筛选所属学科",
					options: [],
				}, {
					prop: "apply_type",
					tag: "select",
					placeholder: "筛选应用学段",
					options: [],
				},
			],
			tableConfig: [
				{
					prop: "subject_name",
					label: "所属学科",
				},
				{
					prop: "apply_type",
					label: "应用学段",
				},
				{
					prop: "knowledge_num",
					label: "知识点总数",
				},
				{
					prop: "video_num",
					label: "关联视频总数",
				},
				{
					handle: true,
					label: "操作",
					render: (h, params) => {
					},
				},
			],
			historyDialogShow: false,
			historyList: [],
		};
	},
	mounted() {
    this.init()
	},
  activated() {
    this.getSubject()
  },
	methods: {
    getSubject() {
      zhGet('/video/search-version',{ search_type: 'article_sub_id' }).then(res=>{
        setConfigOption({ list: this.searchConfig, data: changeObjToArr(res, true), key: 'subject_id'})
      })
    },
    init() {
      // 获取所属学科
      this.getSubject()
      // 获取应用学段
      zhGet('/video/search-version',{ search_type: 'apply_type' }).then(res=>{
        setConfigOption({ list: this.searchConfig, data: changeObjToArr(res, true), key: 'apply_type'})
      })
    },
		getData() {
			this.getDataApi('/wisdom-education/video/list-knowledge')
		},
		onHandel(row, val) {
			if (val.type === "edit")
				this.$router.push(`./edit?id=${row.id}`);
			else
        zh_opt_history({ type: 'video_knowledge_basic', id: row.id }).then(res=>{
          this.historyList = res.list
          this.historyDialogShow = true
        })
		},
	},
};
</script>

<style lang="scss" scoped>
.list {
	padding: 24rem 0 0 32rem;

	.search {
		.el-input,
		.el-select {
			width: 240rem;
			margin-right: 24rem;
		}
	}

	.pagination {
		margin-top: 50rem;
	}
}
</style>
